import encryptString from 'src/utils/encryptString';

const GOALS_CARD_SECRET_KEY = '1yUdXFT7pKmL6Z==';

let allowedItemIds_set = new Set([]);

const manager = {
  getList: async () => {
    const list =
      allowedItemIds_set.size > 0 ? Array.from(allowedItemIds_set) : null;

    if (!list) return { encryptedData: null };

    const encryptedData = await encryptString(list, GOALS_CARD_SECRET_KEY);
    return encryptedData;
  },
  addItemId: (itemId) => {
    allowedItemIds_set.add(itemId);
  },
  clearEverything: () => {
    allowedItemIds_set = new Set([]);
  },
};

export { manager };
